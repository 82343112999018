import labt from "../images/labtron.svg";
import arvr from "../images/gdxr.svg";
export const Bio = {
  name: "Piyush Raj",
  roles: [
    "Full Stack Developer",
    "</> Programmer",
    "Game Developer",
    "Cybersecurity Specialist",
  ],
  description:
    "I am a motivated and adaptable individual, eager to take on new challenges and continuously grow. With a passion for learning and delivering high-quality results, I bring a positive attitude and a proactive approach to every task. I am committed to making a meaningful impact and contributing to the success of any team or project.",
  github: "https://github.com/Legend-Raj",
  resume:
    "https://drive.google.com/file/d/1Ok1ZEFF0b9ClH8BURLgNPp7uJ7upVoX_/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/piyush-raj-2a5b58259/",
  twitter: "",
  insta: "",
  facebook: "",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg",
      },
      {
        name: "Redux",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg",
      },
      {
        name: "Next Js",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original-wordmark.svg",
      },
      {
        name: "HTML",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg",
      },
      {
        name: "CSS",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
      },
      {
        name: "Bootstrap",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/b/b2/Bootstrap_logo.svg",
      },
      {
        name: "Three.js",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/threejs/threejs-original.svg",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
      },
      {
        name: "Express Js",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "Flask",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
      },
      {
        name: "Django",
        image:
          "https://static.djangoproject.com/img/logos/django-logo-positive.svg",
      },
    ],
  },
  {
    title: "Databases",
    skills: [
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "PostgreSQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
      {
        name: "Firebase",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
      },
    ],
  },
  {
    title: "Cloud and DevOps",
    skills: [
      {
        name: "AWS",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
      },
      {
        name: "Google Cloud",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/googlecloud/googlecloud-original-wordmark.svg",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "Kubernetes",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/kubernetes/kubernetes-plain.svg",
      },
      {
        name: "Vercel",
        image: "https://www.svgrepo.com/show/361653/vercel-logo.svg",
      },
    ],
  },
  {
    title: "Machine Learning and Tools",
    skills: [
      {
        name: "TensorFlow",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/tensorflow/tensorflow-original.svg",
      },
      {
        name: "Google Colab",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/d/d0/Google_Colaboratory_SVG_Logo.svg",
      },
      {
        name: "Power BI",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/cf/New_Power_BI_Logo.svg",
      },
      {
        name: "Excel",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Microsoft_Excel_2013-2019_logo.svg/2048px-Microsoft_Excel_2013-2019_logo.svg.png",
      },
    ],
  },
  {
    title: "Game Development and 3D Modeling",
    skills: [
      {
        name: "Unity",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/unity/unity-original.svg",
      },
      {
        name: "Unreal Engine",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/unrealengine/unrealengine-original.svg",
      },
      {
        name: "Blender",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/0/0c/Blender_logo_no_text.svg",
      },
      {
        name: "Godot",
        image: "https://www.svgrepo.com/show/473624/godotengine.svg",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Visual_Studio_Code_1.35_icon.svg",
      },
      {
        name: "Postman",
        image:
          "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg",
      },
      {
        name: "Figma",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
      },
      {
        name: "Adobe XD",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg",
      },
      {
        name: "Linux",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/35/Tux.svg",
      },
      {
        name: "Ubuntu",
        image: "https://assets.ubuntu.com/v1/29985a98-ubuntu-logo32.png",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: arvr,
    role: "TE, Member",
    company: "Game Development and Extended Reality Club, Pune",
    date: "September 2023 - Present",
    desc: "Conducted college exclusive event 'Gamethon' with 50+ participants. Organized Blender and Unity workshops for 80+ first-year students and hosted a gaming stall for 300+ visitors on Alumni Day. Collaborated with GDXR Club to create a cutting-edge virtual reality campus tour, improving conversion rates by 30%.",
    skills: [
      "Unity",
      "Blender",
      "Virtual Reality",
      "Event Management",
      "Game Development",
    ],
  },
  {
    id: 1,
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD10lEQVR4nO1YTYgdRRDuaMSAiIKKQUUP4kUETVY9KDqZ6pmqdhVvuYgK/h38uyshPjwtnkIUhcW3U/U2/qCiB6MoKgYUQRFU8BD/iKDJxd8kmyUuxF2pyZvdntk3Oz1ZwSfMBwPvUdVV39fTXV09xnTo0KFDhw4dOvw7sEJL+sSS3gzPw4VW6HFgfBeE9lvGn0HoMAj+AkJfWqYMJL0l2hdtPNV8E9MTZ8SZm9RYVuirYezDw1z7NXfC+JhyUU4Fv0YBVvAoCP618n+tB7+xjK4teSUOjN+G5DjJBY+2EOA9THMgNBULTqSz6VnJdHIOCF5vmZ4BxgXPd1ev1zutifj2V7efrmOXyTEugNDTNnPXaew8xyC51go+BUzHRnEKFgCMXySD5NLaWRScAKFD3tt42SyZDXX+KhAYX1mZHDwIGW2tjd+fvEyX1ikJyF/vwJ5X61yMGbgtIDjvjdtR5wuMT3jLYj7l9Jqm+NGLt51vmb5vJ4BpcRunNzYF98Y96Qk4MWpWdVmA4N+egF5o/HwDMy0GC9Ddb1rghv7tZ5fXK75d9QGhd/x9pWvdtIAVei9YQJLhHW2C52MZXyotwRl3ZWFLZ9OrylWFXmgbP2a6K1hAtMdd0jYBCN3vk9T6vWxj3OHbYsF72se/9eJGAeuBVqRK7f6gsFmmD0uVZOC2mHEDnDy5vfMDf1i2MR3wbW7GXWDGDRFHm8plmI4VNr/M5gJ2uzPNuCHaF22sHv+FrXJiL62nf1qFsH5nfB/zvxdQlCgQfKtq1L5FN6cJwLBv8Wv9d4VNf/u2tforHxFPbrZMr40g/qZyLjkn/eSiCvkT2h2aQFjBuytJdhU2ENxdFufuDI0bcXSucintIZ7cPJoE09yyI+MfoUlykozvr4ylRb/WxzN4td/LaFvRJrYV+tOb2CNrOOKPnoBfg8lntLXUqDHtXeXjLVH1VVEmNL7Q717sA/WOTJ97jr+FXgst06fe7M/F4i6v+iVZckXlPPhEx4bk0NXgxf+s3lGwP6qOr3lBEZoprf0M763zTxgfKO8T7Ifc4vxzBISm6x0F49Jmy+imOl9t+LRtLhFi2tlIRrBXLha0d1VF8UUPXFSuYC5qSEBT3oDjOkvA7r5kQKSXd51hy7incgtbAKaHm8h7Ih4pzyrOW8HZYWynuTTn8O0e92Z/qjn6ktlgB+4h3e1hhwl+pJ2oaYn8hsb4cUgO/dSSCD7YKoF2jJbxUcv4hn4HAqGfdKZUGDB9DYzPWqZtZp0AwRiYnhvGPJLnyHNpTnxd39ZYdq8dOnTo0KFDhw7mv8c/hXdKvMBwaFwAAAAASUVORK5CYII=", // Base64-encoded image for GeeksforGeeks
    role: "Campus Ambassador",
    company: "GeeksforGeeks",
    date: "July 2023 - December 2023",
    desc: "Led 15+ workshops on programming, cloud computing, and data visualization. Increased user retention by 60% and reduced bounce rate by 40% on the platform through active engagement and event hosting.",
    skills: [
      "Programming",
      "Cloud Computing",
      "Data Visualization",
      "Leadership",
      "Event Management",
    ],
  },
  {
    id: 2,
    img: labt,
    role: "C++ Interface Developer Intern",
    company: "Labtron",
    date: "August 2024 - December 2024",
    desc: "Joined Labtron as a C++ Interface Developer Intern. Developed interfaces for industrial-level lab instruments, ensuring seamless communication and integration. Responsibilities included adhering to company policies and maintaining code quality. Internship offer included a stipend of INR 10,000/month.",
    skills: [
      "C++",
      "Interface Development",
      "Code Quality",
      "Communication",
      "Problem Solving",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "https://upload.wikimedia.org/wikipedia/en/6/6a/AIT_Pune_logo.gif",
    school: "Army Institute Of Technology, Pune",
    date: "AUG 2022 - JUL 2026",
    grade: "8.41 CGPA",
    desc: "I am currently pursuing a Bachelor's degree in Information Technology at Army Institute Of Technology, Pune. I have completed 4 semesters and have a CGPA of 8.47. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I am also a member of the Game Development and Xtended Reality at AIT, where I am learning and working on exciting projects with a team of talented developers.",
    degree: "Bachelor of Engineering- BE Information Technology",
  },
];

export const projects = [
  {
    id: 0,
    title: "Dual-Time Sync",

    description:
      "Developed a Digital and Circular Clock using only JavaScript, CSS, and HTML without any external APIs. It features a digital time display and an analog clock dial, along with a theme switcher for light and dark modes.",
    image:
      "https://github.com/user-attachments/assets/24f61794-01da-4175-8c54-bb80e7ec8cba",
    tags: ["HTML", "CSS", "JS"],
    category: "web app",
    github: "https://github.com/Legend-Raj/Watch.git",
    webapp: "https://watch.piyushraj.tech/",
  },
  {
    id: 1,
    title: "TaskMaster",
    // date: "Oct 2022 - Jan 2023",
    description:
      "TaskMaster Pro is a clean and intuitive To-Do List app built with React and Tailwind CSS, allowing users to add, complete, edit, and delete tasks seamlessly. Perfect for simple, efficient task management.",
    image:
      "https://github.com/user-attachments/assets/58e33a38-16e4-4d20-9a90-16216e4aa184",
    tags: ["React", "Tailwind CSS", "JavaScript", "HTML", "CSS"],
    category: "web app",
    github: "https://github.com/Legend-Raj/Todo-List.git",
    webapp: "https://todo.piyushraj.tech/",
  },
  {
    id: 2,
    title: "Simon Game",
    // date: "Jan 2023 - Mar 2023",
    description:
      "The game presents you with a sequence of colored buttons. Your task is to memorize and then replicate the sequence precisely by pressing the buttons in the correct order. Each successful round adds another color to the sequence, gradually increasing the difficulty and testing your ability to remember.",
    image:
      "https://github.com/user-attachments/assets/f4b2650e-7010-4ea0-97d2-44c1b29c3794",
    tags: ["HTML", "CSS", "JS"],
    category: "web app",
    github: "https://github.com/Legend-Raj/Simon-Game.git",
    webapp: "https://simon.piyushraj.tech/",
  },
  {
    id: 3,
    title: "AI ChatMate",
    // date: "Dec 2021 - Apr 2022",
    description:
      "developed a chatbot integrating ChatGPT for conversational AI and DALL·E for generating images based on prompts. The chatbot is designed to assist students with various inquiries related to the college, such as course information, event details, and general support. By combining the power of natural language processing and image generation, the bot offers a rich, interactive experience. This project aims to enhance student assistance on the college website, making information more accessible and visually engaging",
    image:
      "https://github.com/user-attachments/assets/70e32e06-2a75-4d8d-a629-773a68f01be0",
    tags: ["HTML", "CSS", "JS", "API"],
    category: "web app",
    github: "https://github.com/Legend-Raj/aichat.git",
    webapp: "https://aichat.piyushraj.tech/",
  },
  {
    id: 4,
    title: "AIT VR TOUR",
    // date: "Jul 2021",
    description:
      " As part of a collaborative team project, Orchestrated the development of a 3D VR tour of the college, leveraging Blender for detailed models, Unity for seamless integration, resulting in an immersive experience; presented on GitHub for team collaboration and feedback.",
    image:
      "https://github.com/user-attachments/assets/c3bf8833-16c3-4898-a9e2-490ed7afd3dc",
    tags: ["blender", "Unity", "UnrealEngine", "MetaSpark", "GAEA"],
    category: "3D GameDev",
    github: "https://github.com/Legend-Raj/AIT-3D-Model-.git",
    webapp:
      "https://drive.google.com/file/d/1oIS7VlosaHJ2eyAXPxy9HhgSub8XCjTA/view?usp=sharing",
  },
  {
    id: 5,
    title: "Trishool Portal",
    // date: "Jun 2021",
    description:
      " As a key member of the team, contributed to the development of ”Trishool Portal,” a game created for our club's event, ”GenesisIII,” by focusing on error fixing, bug resolution, script creation, and the design of portal 3D models",
    image:
      "https://github.com/user-attachments/assets/bd4703d3-c497-40b6-be81-2904171641ba",
    tags: ["GODOT", "Unity", "Blender", "Unreal Engine"],
    category: "3D GameDev",
    github: "https://itch.io/jam/genesis3/rate/2560876",
    webapp: "https://itch.io/jam/genesis3/rate/2560876",
  },
  {
    id: 6,
    title: "Drowsiness Detection",
    // date: "Jun 2021",
    description:
      "This project detects driver drowsiness to help reduce the risk of accidents caused by fatigue. It uses real-time video streams to analyze eye movements and other facial landmarks to determine the driver's state of alertness.",
    image:
      "https://github.com/user-attachments/assets/4824c762-d989-4fe3-b080-ccc186a5ebf6",
    tags: ["Numpy", "sklearn", "google Colab", "Python"],
    category: "machine learning",
    github: "https://github.com/Legend-Raj/Drowsiness-Detection.git",
    webapp: "https://github.com/Legend-Raj/Drowsiness-Detection.git",
  },
  {
    id: 7,
    title: "Tic-Tac-Toe Game",
    // date: "Dec 2020 - Jan 2021",
    description:
      "Developed an interactive command-line Tic Tac Toe game in C++ with real-time board updates, user input validation, and win/tie detection. The game features alternating player turns, visual color cues, and allows replay options.",
    image:
      "https://github.com/user-attachments/assets/433fe703-c4ab-4a04-9f3c-c8f8e6e6df86",
    tags: ["c++"],
    category: "android app",
    github: "https://github.com/Legend-Raj/TicTacThrill-Cpp.git",
    webapp: "https://github.com/Legend-Raj/TicTacThrill-Cpp.git",
  },
  {
    id: 8,
    title: "Algo Sphere",
    // date: "Jan 2021",
    description:
      "AlgoSphere is an interactive tool that visually demonstrates algorithm behavior, helping users explore and understand algorithms more effectively.Eg. Sorting Techniques ",
    image:
      "https://github.com/user-attachments/assets/4b277170-55d3-4abf-918e-78115c4f4175",
    tags: ["C++", "HTML", "CSS", "JS", "TAILWIND", "React"],
    category: "web app",
    github: "https://github.com/Legend-Raj/AlgoSphere.git",
    webapp: "https://algo.piyushraj.tech/#/",
  },
  {
    id: 9,
    title: "ECOMMERCE DASHBOARD",
    // date: "Jun 2023 - Jul 2023",
    description:
      "Power BI project that provides a comprehensive view of sales performance for an eCommerce business. The dashboard is designed to visualize and analyze key metrics that are critical for tracking sales, customer engagement, and business growth.",
    image:
      "https://github.com/user-attachments/assets/3223eb5d-b4c7-4680-a388-753f4f77c453",
    tags: [
      "Power BI",
      "Python",
    ],
    category: "weba app",
    github: "https://github.com/Legend-Raj/ECOMMERCE-DASHBOARD.git",
    webapp: "https://github.com/Legend-Raj/ECOMMERCE-DASHBOARD.git",
  },
  {
    id: 10,
    title: "Network Packet Sniffer",
    // date: "Jun 2023 - Jul 2023",
    description:
      "This project is a simple network packet sniffer built using Python. It captures and analyzes raw network packets in real time, displaying detailed information such as Ethernet frame data, IPv4 packets, and protocol-specific details (TCP, UDP, ICMP) in a structured format.",
    image:
      "https://github.com/user-attachments/assets/6c55afb3-d8ae-4397-9701-be3a6f02e4f6",
    tags: ["Pyhton", "Networking", "TCP"],
    category: "android app",
    github: "https://github.com/Legend-Raj/Network_Packet_Sniffer.git",
    webapp: "https://github.com/Legend-Raj/Network_Packet_Sniffer.git",
  },
  
];
