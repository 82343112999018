// General spring settings for smoother animation
export const transition = {
  type: "spring",
  damping: 10,       // Increased damping for smoother deceleration
  stiffness: 50,     // Adjust stiffness for smoother motion
  restDelta: 0.01,   // Minimal rest delta for fine-tuned settling
  duration: 0.6,     // Shortened to make it snappier
  delay: 0,
};

// Slide animation with improved fluidity and easing
export const slideAnimation = (direction) => {
  return {
    initial: {
      x: direction === "left" ? -150 : direction === "right" ? 150 : 0,
      y: direction === "up" ? 150 : direction === "down" ? -150 : 0,
      opacity: 0,
      transition: { ...transition, duration: 0.7, delay: 0.3 }, // Smoother timing and delay
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { ...transition, duration: 0.8, ease: [0.43, 0.13, 0.23, 0.96] }, // Adding cubic-bezier easing
    },
    exit: {
      x: direction === "left" ? -150 : direction === "right" ? 150 : 0,
      y: direction === "up" ? 150 : direction === "down" ? -150 : 0,
      opacity: 0,
      transition: { ...transition, duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }, // Smooth exit
    },
  };
};

// Fading animation with smoother transitions
export const fadeAnimation = {
  initial: {
    opacity: 0,
    transition: { ...transition, duration: 0.5, delay: 0.3 },  // Refined delay
  },
  animate: {
    opacity: 1,
    transition: { ...transition, duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }, // Adding easing
  },
  exit: {
    opacity: 0,
    transition: { ...transition, duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] }, // Shortened duration for snappier exit
  },
};

// Headline text animation with subtle spring effect
export const headTextAnimation = {
  initial: { x: 120, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: {
    type: "spring",
    damping: 8,            // Balanced damping for smooth deceleration
    stiffness: 50,         // Refined stiffness for natural bounce
    restDelta: 0.001,
    duration: 0.4,         // Slightly longer to feel smoother
  },
};

// Headline content animation with improved spring dynamics
export const headContentAnimation = {
  initial: { y: 120, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: {
    type: "spring",
    damping: 10,           // Softer damping for content animations
    stiffness: 35,         // More flexible spring
    restDelta: 0.001,
    duration: 0.6,
    delay: 0.2,            // Delayed start for staging
    delayChildren: 0.2,    // Staggered appearance for child elements
  },
};

// Container animation with subtle movement
export const headContainerAnimation = {
  initial: { x: -120, opacity: 0, transition: { ...transition, duration: 0.6, delay: 0.4 } }, // Smooth entry
  animate: { x: 0, opacity: 1, transition: { ...transition, duration: 0.7, ease: [0.43, 0.13, 0.23, 0.96] } }, // Smoother transitions
  exit: { x: -120, opacity: 0, transition: { ...transition, duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] } }, // Smooth exit
};
